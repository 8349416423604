import styled from "styled-components"
import {
  space,
  color,
  flexbox,
  layout,
  grid,
  typography,
  border,
  position,
  variant,
} from "styled-system"

export const Box = styled.div(color, layout, border, space, position)

export const Flex = styled.div(
  {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  color,
  position,
  flexbox,
  layout,
  space,
  border
)

export const Grid = styled.div({ display: "grid" }, color, grid, layout, space)

// Typography
export const Title = styled.h1(
  {
    fontFamily: "Source Sans Pro",
    textAlign: "center",
    fontSize: "48px",
    fontWeight: "normal",
  },
  color,
  space,
  typography,
  position
)
export const Text = styled.p(
  {
    fontFamily: "Open Sans",
    textAlign: "center",
    width: "100%",
    marginBottom: 0,
    fontWeight: 400,
  },
  color,
  space,
  position,
  typography,
  layout
)
export const Label = styled.span(
  {
    fontFamily: "Open Sans",
    textTransform: props => (props.down ? "capitalise" : "uppercase"),
  },
  color,
  space,
  typography
)

export const Button = styled.button(
  variant({
    variants: {
      outline: {
        outline: "none",
        padding: "12px 32px",
        backgroundColor: "transparent",
        border: "2px black solid",
        fontFamily: "Open Sans",
        fontSize: 14,
        cursor: "pointer",
      },
      outlineLight: {
        outline: "none",
        padding: "12px 32px",
        backgroundColor: "transparent",
        border: "2px white solid",
        fontFamily: "Open Sans",
        fontSize: 14,
        color: "white",
        cursor: "pointer",
      },
      filled: {
        outline: "none",
        padding: "12px 32px",
        backgroundColor: "secondary",
        border: "2px black solid",
        fontFamily: "Open Sans",
        color: "white",
        fontSize: 18,
        cursor: "pointer",
        borderRadius: 5,
        "&:hover": {
          backgroundColor: "white",
          color: "black",
        },
      },
      filledLight: {
        outline: "none",
        padding: "12px 32px",
        backgroundColor: "white",
        border: "2px white solid",
        fontFamily: "Open Sans",
        color: "black",
        fontSize: 14,
        cursor: "pointer",
      },
    },
  }),
  space,
  typography
)
