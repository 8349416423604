import React from "react"
import { ThemeProvider } from "styled-components"

import theme from "./theme"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <main>{children}</main>
    </ThemeProvider>
  )
}

export default Layout
